export const NAVIGATION_ROUTE = {
  homepage: "/",
  blogs: "",
  singleBlog: "",
  contactUs: '/contact-us',
  disclaimer:'/disclaimer',
  privacyPolicy:'/privacy-policy',
  cookiePolicy:'/cookie-policy',
  termsAndConditions:'/terms-and-conditions',
  writeForUs:'write-for-us',
  aboutUs:'/about-us',
  termsAndServices:'terms-and-services'
};
